


































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import TeaserButton from '@/components/common/TeaserButton.vue'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'

@Component({
  components: { TeaserButton }
})
export default class MessagingAdministration extends Vue {
  get hasRightSend (): boolean {
    return hasRight(Rights.MESSAGE_WRITE)
  }
}
